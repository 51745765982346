/*
 * @Author: lbh
 * @Date: 2022-09-23 10:31:25
 * @LastEditors: lbh
 * @LastEditTime: 2023-03-02 14:10:37
 * @Description: 插件
 */
import selfTopWhatsApp from './topWhatsApp.vue';

let currentTWA;
export default {
  install(Vue, options) {
    let f = () => {
      // 避免出现多个 toast 重叠
      if (currentTWA) {
        currentTWA.close();
      }
      document.body.classList.add('overflowHidden');
      currentTWA = _create({
        Vue,
        onClose: () => {
          document.body.classList.remove('overflowHidden');
          currentTWA = null;
        },
      });
    };
    // 託管到 vue
    Vue.prototype.$topWhatsApp = f;
    // 託管到 window
    window.$topWhatsApp = f;
  },
};

function _create({ Vue, onClose }) {
  const Constructor = Vue.extend(selfTopWhatsApp);
  let twa = new Constructor({
    el: document.createElement('div'), // 設置掛載點, 不寫這個會報錯的哦
  });
  twa.$on('close', onClose);
  // 加載到 body
  document.body.appendChild(twa.$el);
  return twa;
}
