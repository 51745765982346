/*
 * @Author: lbh
 * @Date: 2022-10-12 10:07:49
 * @LastEditors: lbh
 * @LastEditTime: 2022-10-12 11:44:47
 * @Description: file content
 */
let event = {
  //创建一个数组用来放订阅的消息
  allFn: {},
  //监听函数
  listen(key, fn) {
    fn.call(this, window.innerWidth);
    this.allFn[key] = fn; // 订阅的消息添加进缓存列表
  },
  //触发函数
  trigger(key, e) {
    for (const i in this.allFn) {
      if (key == i) {
        this.allFn[i].call(this, e);
        break;
      }
    }
  },
  triggerAll(e) {
    for (const i in this.allFn) {
      this.allFn[i].call(this, e);
    }
  },
  //移除监听
  removeListen(key) {
    //如果没有写fn，移除全部监听事件
    delete this.allFn[key];
  },
};

export default event;
