<!--
 * @Author: lbh
 * @Date: 2024-04-01 14:08:16
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-12 15:45:07
 * @Description: 漸進式圖片
-->
<template>
  <el-image
    :src="src"
    class="progressive-image"
    lazy
  >
    <img
      slot="placeholder"
      class="image-slot"
      :src="$util.image.setOss(src,h)"
    />
    <div
      slot="error"
      class="image-slot"
    ></div>
  </el-image>
</template>

<script>
export default {
  name: "progressive-image",
  props: {
    src: {
      type: String,
      default: ''
    },
    h: {
      type: Number,
      default: 50
    }
  },
}
</script>

<style lang="less" scoped>
.progressive-image {
  & /deep/ img {
    width: 100%;
    height: 100%;
  }
}
</style>