<!--
 * @Author: lbh
 * @Date: 2022-10-15 11:27:07
 * @LastEditors: lzh
 * @LastEditTime: 2022-12-09 14:36:35
 * @Description: file content
-->
<template>
  <div
    class="self-button-box px-self-button-box"
    :class="`${theme}`"
    @click="onClick"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "self-button",
  props: {
    theme: {
      default () {
        return ''
      }
    },
    go: {
      default () {
        return '';
      }
    },

  }
  ,
  methods: {
    onClick () {
      if (this.go)
        this.$util.route.go(this.go)
      else
        this.$emit('onClick')
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 1000px) {
  .self-button-box {
    padding: 0 30px;
    height: 45px;
    line-height: 45px;
    display: table;
    cursor: pointer;
    color: #fff;
    background-color: var(--themeColor);
    border-radius: 6px;
    border: 1px solid var(--themeColor);
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    &.plain {
      color: var(--themeColor);
      border: 1px solid var(--themeColor);
      background-color: transparent;
    }
    &.none {
      color: var(--themeColor);
      border: none;
      background-color: transparent;
    }
    &.line {
      color: #999;
      border: 1px solid #999;
      background-color: transparent;
    }
  }
}
@media screen and (max-width: 1000px) {
  .px-self-button-box {
    height: 38px;
    line-height: 38px;
    padding: 0 28px;
    display: table;
    color: #fff;
    background-color: var(--themeColor);
    border: 1px solid var(--themeColor);
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    border-radius: 6px;
    &.plain {
      color: var(--themeColor);
      border: 1px solid var(--themeColor);
      background-color: transparent;
    }
    &.none {
      color: var(--themeColor);
      border: none;
      background-color: transparent;
    }
    &.line {
      color: #999;
      border: 1px solid #999;
      background-color: transparent;
    }
  }
}
@media screen and (max-width: 500px) {
  .px-self-button-box {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    display: table;
    color: #fff;
    border: 1px solid var(--themeColor);
    background-color: var(--themeColor);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    min-width: 60px;
    text-align: center;
    &.plain {
      color: var(--themeColor);
      border: 1px solid var(--themeColor);
      background-color: transparent;
    }
    &.none {
      color: var(--themeColor);
      border: none;
      background-color: transparent;
    }
    &.line {
      color: #999;
      border: 1px solid #999;
      background-color: transparent;
    }
  }
}
</style>