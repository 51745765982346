<!--
 * @Author: lbh
 * @Date: 2022-02-24 15:34:34
 * @LastEditors: lbh
 * @LastEditTime: 2023-11-08 10:19:04
 * @Description: 上傳圖片
-->
<template>
  <div class="com__upload-box ">
    <el-button
      v-if="url"
      type="danger"
      icon="el-icon-delete"
      class="remove-img"
      size="mini"
      circle
      @click="removeFile"
    ></el-button>
    <el-upload
      class="avatar-uploader"
      :class="type"
      action="#"
      :http-request="uploadFile"
      :show-file-list="false"
    >
      <img
        v-if="url"
        :style="`border-radius:${radius}px`"
        :src="url"
        class="avatar"
      />
      <i
        v-else
        class="el-icon-plus avatar-uploader-icon"
      ></i>
    </el-upload>
  </div>
</template>

<script>
import { getSignature, ossUploadImg } from "@/js/api/api";
export default {
  name: "self-upload",
  props: {
    url: {
      default () {
        return '';
      }
    },
    radius: {
      default () {
        return 0
      }
    },
    type: {
      default () {
        return "auto"; // square
      }
    }
  },
  model: {
    prop: "url",
    event: "success"
  },
  methods: {
    uploadFile (e) {
      console.log(e);
      this.uploadFileByOss(e.file, res => {
        console.log(res);
        if (res.success) {
          this.$emit('success', res.data);
          this.$emit('change', res.data);
        }
      });
    },
    removeFile () {
      this.$emit('success', '');
      this.$emit('change', '');
    },
    /**
     * 上傳文件
     * @param { file } obj
     * @param { function } back
     */
    uploadFileByOss (file, back = () => { }) {
      getSignature().then((res) => {
        if (res.success) {
          let policyData = res.data;
          let ossUrl = policyData.host;
          let name = file.name || '';
          if (name) {
            name = file.name.split('.')[1];
          } else {
            name = '.png';
          }
          let accessUrl = policyData.dir + '/' + Date.now() + name; //上傳路徑
          let sendData = new FormData();
          sendData.append('OSSAccessKeyId', policyData['accessKeyId']);
          sendData.append('policy', policyData['encodedPolicy']);
          sendData.append('Signature', policyData['postSignature']);
          sendData.append('keys', policyData['dir']);
          sendData.append('key', accessUrl); //上传的文件路径
          sendData.append('success_action_status', 200); // 指定返回的状态码
          sendData.append('type', 'image/*');
          sendData.append('file', file);
          sendData.append('configType', 'ossFile');
          // 開始上傳
          ossUploadImg(ossUrl, sendData)
            .then((res) => {
              // 成功后没有回调， 只要觸發了 then , 就默認為成功
              let file = ossUrl + '/' + accessUrl; //获得到的url需要将其存数据库中
              back({
                success: true,
                data: file,
              });
            })
            .catch((e) => {
              back({
                success: false,
              });
            });
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.com__upload-box {
  position: relative;
  .remove-img {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 6;
  }
  &:hover {
    filter: drop-shadow(0px 0px 0px black);
  }
  & /deep/ .avatar-uploader {
    &.square {
      width: 60px;
      height: 60px;
      .avatar {
        width: 60px;
        height: 60px;
      }
    }
    .el-upload {
      min-width: 80px;
      min-height: 80px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      &::hover {
        border-color: #409eff;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100%;
      margin-top: 50%;
      transform: translateY(-50%);
      text-align: center;
    }
    .avatar {
      width: 100%;
      display: block;
    }
  }
}
</style>