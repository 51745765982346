/*
 * @Author: lbh
 * @Date: 2020-12-24 18:12:01
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-17 10:51:05
 * @Description: axios 二次封裝
 */
// 導入 vue 框架
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import setting from '../../../package.json';

Vue.prototype.axios = axios;

// 請求攔截
axios.interceptors.request.use(
  (config) => {
    let contentType =
      config.headers['Content-Type'] ||
      config.headers['Content-type'] ||
      config.headers['content-type'] ||
      config.headers['content-Type'];
    if (
      config.method == 'post' &&
      contentType.indexOf('application/x-www-form') > -1 &&
      config.url.indexOf('aliyuncs.com') == -1
    )
      config.data = qs.stringify(config.data);
    else if (
      config.method == 'post' &&
      contentType.indexOf('application/json') > -1
    )
      config.data = JSON.stringify(config.data);
    // 記錄請求頭
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 響應 攔截
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      return Promise.reject(error.response);
    }
  }
);

let https = {
  /**
   * 獲取 頭部信息
   */
  setHeaders(object) {
    let contentType = '';
    if (typeof object == 'object') {
      if (object.contentType) contentType = object.contentType;
    } else contentType = object;
    let headers = {
      'Content-type':
        contentType || 'application/x-www-form-urlencoded;charset=UTF-8',
      apptype: 13,
      version: 'official',
      ver: setting.version,
    };
    if (typeof object == 'object') {
      if (object.origin) headers['Access-Control-Allow-Origin'] = '*';
      if (object.uid) headers.uid = object.uid;
      if (object.apptype) headers.apptype = object.apptype;
    }
    console.log(headers);
    return headers;
  },
  /**
   * get 請求
   * @param {string} url
   * @param {object} params
   */
  get(url, params, headers = {}) {
    console.log(url, params);
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: this.setHeaders(headers),
          params: params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  /**
   * post 請求
   * @param {string} url
   * @param {object} params
   */
  post(url, params, headers) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers: this.setHeaders(headers),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  /**
   * form 表单提交
   * @param {*} url
   * @param {*} params
   * @returns
   */
  form(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers: this.setHeaders('multipart/form-data'),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  /**
   * json 格式提交
   * @param {*} url
   * @param {*} params
   * @returns
   */
  body(url, params, uid) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers: this.setHeaders({
            uid: uid,
            contentType: 'application/json',
          }),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  /**
   * 圖片上傳
   * @param {*} url
   * @param {*} params
   * @returns
   */
  upload(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers: this.setHeaders({
            origin: '*',
          }),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
};

export default https;
