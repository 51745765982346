<!--
 * @Author: lbh
 * @Date: 2024-01-04 10:28:26
 * @LastEditors: lbh
 * @LastEditTime: 2024-02-26 10:44:50
 * @Description:全屏廣告
-->
<template>
  <div
    class="full-screen-ad-box"
    :class="{isEdit:isEdit}"
  >

    <!-- 編輯時的快速跳轉 -->
    <div
      v-if="isEdit || showQuickEdit"
      class="edit-quick"
    >
      全屏廣告快捷切換
      <div
        class="cell"
        @click="dialogVisibleEvent(0)"
      >第一步</div>
      <div
        class="cell"
        @click="dialogVisibleEvent(1)"
      >第二步</div>
      <div
        class="cell"
        @click="dialogVisibleEvent(2)"
      >第三步</div>
    </div>

    <!-- 載體 -->
    <div
      v-if="1 == 2"
      class="fc-btn"
      @click="dialogVisible = true"
    >
      廣告載體
    </div>

    <!-- 全屏廣告彈窗 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :destroy-on-close="true"
      :append-to-body="appendBody"
      :modal-append-to-body="appendBody"
      class="fc-dialog"
      @close="$util.ele.removeModal()"
    >
      <div
        class="fc-dialog-box"
        @click="clickItem('fc-dialog')"
        :style="{
          '--fcBodyBgStart':$util.window.check(configs.first.bg.bgStartByPhone,configs.first.bg.bgStart),
          '--fcBodyBgEnd':$util.window.check(configs.first.bg.bgEndByPhone,configs.first.bg.bgEnd),
          '--fcBodyMinRadius':$util.window.check(configs.first.bg.minRadiusByPhone,configs.first.bg.minRadius),
          '--fcBodyMaxRadius':$util.window.check(configs.first.bg.maxRadiusByPhone,configs.first.bg.maxRadius)
          }"
      >
        <!-- × -->
        <div
          class="fc-close"
          @click.stop="dialogVisibleClose"
        >
          <img src="../../assets/default/Frame@2x.png" />
        </div>
        <div class="fc-dialog-body">
          <!-- 描述區域 -->
          <div class="fc-header">
            <!-- 文字描述 -->
            <div class="content-box">
              <div
                class="title"
                :style="{
                  '--fcTitleFontSize':$util.window.check(configs.first.title.fontSizeByPhone,configs.first.title.fontSize),
                  '--fcTitleBgStart':$util.window.check(configs.first.title.bgStartByPhone,configs.first.title.bgStart),
                  '--fcTitleBgEnd':$util.window.check(configs.first.title.bgEndByPhone,configs.first.title.bgEnd),
                  '--fcTitleColor':$util.window.check(configs.first.title.fontColorByPhone,configs.first.title.fontColor),
                  '--fcTitleShadowColor':$util.window.check(configs.first.title.shadowColorByPhone,configs.first.title.shadowColor),
                  }"
                v-html="$util.rex.getHtml($util.window.check(configs.first.title[`${nowL.code}textByPhone`] || configs.first.title.textByPhone,configs.first.title[`${nowL.code}text`] || configs.first.title.text))"
              ></div>
              <div
                class="desc"
                :style="{
                  '--fcDescFontSize':$util.window.check(configs.first.desc.fontSizeByPhone,configs.first.desc.fontSize),
                  '--fcDescColor':$util.window.check(configs.first.desc.fontColorByPhone,configs.first.desc.fontColor),
                  '--fcDescShadowColor':$util.window.check(configs.first.desc.shadowColorByPhone,configs.first.desc.shadowColor),
                   '--fcDescLineHeight':$util.window.check(configs.first.desc.fontHeightByPhone,configs.first.desc.fontHeight)
                  }"
                v-html="$util.rex.getHtml($util.window.check(configs.first.desc[`${nowL.code}textByPhone`] || configs.first.desc.textByPhone,configs.first.desc[`${nowL.code}text`] || configs.first.desc.text))"
              ></div>
              <div
                class="other"
                :style="{
                  '--fcOtherFontSize':$util.window.check(configs.first.other.fontSizeByPhone,configs.first.other.fontSize),
                  '--fcOtherColor':$util.window.check(configs.first.other.fontColorByPhone,configs.first.other.fontColor),
                  '--fcOtherBgStart':$util.window.check(configs.first.other.lineBgStartByPhone,configs.first.other.lineBgStart),
                  '--fcOtherBgEnd':$util.window.check(configs.first.other.lineBgEndByPhone,configs.first.other.lineBgEnd)
                  }"
                v-html="$util.rex.getHtml($util.window.check(configs.first.other[`${nowL.code}textByPhone`] || configs.first.other.textByPhone,configs.first.other[`${nowL.code}text`] || configs.first.other.text))"
              ></div>
            </div>
            <!-- 圖片 -->
            <div class="img-box">
              <img :src="configs.first.img.url" />
              <div
                class="shadow"
                :style="{
                  '--fcImgShadowBg':$util.window.check(configs.first.img.shadowBgByPhone,configs.first.img.shadowBg)
                  }"
              ></div>
            </div>
          </div>
          <!-- 套餐區域 -->
          <div
            class="fc-package"
            :style="{
              '--fcPackageBg':$util.window.check(configs.first.product.bgByPhone,configs.first.product.bg),
              '--fcPackageBorderColor':$util.window.check(configs.first.product.borderColorByPhone,configs.first.product.borderColor),
              '--fcPackageFontSize':$util.window.check(configs.first.product.fontSizeByPhone,configs.first.product.fontSize),
              '--fcPackageFontColor':$util.window.check(configs.first.product.fontColorByPhone,configs.first.product.fontColor)
              }"
          >
            <div
              v-for="item,index in configs.first.product.childs"
              :key="index"
              class="fc-package-grid"
            >
              <div class="fc-p-g-img">
                <img :src="$util.window.check(item.iconByPhone,item.icon)" />
              </div>
              <div
                class="fc-p-g-title"
                v-html="$util.rex.getHtml($util.window.check(item[`${nowL.code}titleByPhone`] || item.titleByPhone,item[`${nowL.code}title`] || item.title))"
              ></div>
            </div>
          </div>
          <!-- 按鈕 -->
          <div
            class="fc-submit"
            :style="{
              '--fcBtnBgStart':$util.window.check(configs.first.btn.bgStartByPhone,configs.first.btn.bgStart),
              '--fcBtnBgEnd':$util.window.check(configs.first.btn.bgEndByPhone ,configs.first.btn.bgEnd),
              '--fcBtnFontSize':$util.window.check(configs.first.btn.fontSizeByPhone,configs.first.btn.fontSize),
              '--fcBtnFontColor':$util.window.check(configs.first.btn.fontColorByPhone,configs.first.btn.fontColor)
              }"
          >
            <div
              class="fc-submit-btn"
              @click="submitEvent"
              v-html="$util.rex.getHtml($util.window.check(configs.first.btn[`${nowL.code}textByPhone`] || configs.first.btn.textByPhone,configs.first.btn[`${nowL.code}text`] || configs.first.btn.text))"
            ></div>
          </div>
        </div>
      </div>

    </el-dialog>

    <!-- 立即登記 -->
    <el-dialog
      title=""
      :visible.sync="dialogRegisterVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :destroy-on-close="true"
      class="fc-register-dialog"
      :append-to-body="appendBody"
      :modal-append-to-body="appendBody"
      @close="$util.ele.removeModal()"
    >
      <template #title>
        <div
          class="h-title"
          v-text="t_('fullTitle','RicePOS，您的店鋪經營專家')"
        ></div>
        <div
          class="h-icon"
          @click="dialogVisibleClose"
        >
          <i class="el-icon-close"></i>
        </div>
      </template>
      <div class="fr-body">
        <el-form
          ref="form"
          :model="subForm"
          :rules="subRules"
        >
          <el-form-item
            label=""
            prop="mobile"
          >
            <div class="phone-box">
              <el-select
                v-model="subForm.countryId"
                placeholder=""
              >
                <el-option
                  v-for="item in region"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <!-- :placeholder="t_('topTelphonePlaceholder','請輸入您的聯絡電話')" -->
              <el-input
                v-model.number="subForm.mobile"
                :maxlength="subForm.countryId == '3' ? 11 : 8"
                :placeholder="t_('fullPhonePlaceholder','請輸入您的聯絡電話')"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item
            label=""
            class="sms-send"
          >
            <el-input
              v-model="subForm.code"
              :placeholder="t_('fullSmsPlaceholder','請輸入驗證碼')"
              maxlength="6"
            >
              <template #suffix>
                <el-button
                  color="var(--themeColor)"
                  size="small"
                  :loading="smsLoading"
                  @click="sendMsg"
                >{{smsSuccess ? `${timeout}S` : t_('fullSmsText','獲取驗證碼')}}</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="userName"
          >
            <el-input
              v-model="subForm.userName"
              :placeholder="t_('fullNamePlaceholder','請設置您的賬戶名')"
              maxlength="16"
            />
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="subForm.passWord"
              :placeholder="t_('fullPassPlaceholder','請設置密碼')"
              maxlength="20"
              :show-password="true"
              type="password"
            />
          </el-form-item>
          <el-form-item class="submit">
            <el-button
              type="primary"
              :loading="subLoading"
              @click="subLogin"
            >{{t_('fullBtnText','登記並獲取免費體驗名額')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 登記成功 -->
    <el-dialog
      title=""
      :visible.sync="dialogSuccessVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :append-to-body="appendBody"
      :modal-append-to-body="appendBody"
      :destroy-on-close="true"
      @close="dialogVisibleClose"
      class="fc-success-dialog"
      @closed="$util.ele.removeModal()"
    >
      <div
        class="fc-s-box"
        @click="clickItem('fc-success-dialog')"
        :style="{
          '--fcsBgColor':$util.window.check(configs.third.bgColorByPhone,configs.third.bgColor),
          '--fcsTitleFontSize':$util.window.check(configs.third.titleFontSizeByPhone,configs.third.titleFontSize),
          '--fcsTitleFontColor':$util.window.check(configs.third.titleFontColorByPhone,configs.third.titleFontColor),
          '--fcsDescFontSize':$util.window.check(configs.third.descFontSizeByPhone,configs.third.descFontSize),
          '--fcsDescFontColor':$util.window.check(configs.third.descFontColorByPhone,configs.third.descFontColor),
        }"
      >
        <!-- 頭部 -->
        <div
          class="fc-s-head"
          :style="{backgroundImage:`url('${$util.window.check(configs.third.headBgByPhone,configs.third.headBg)}')`}"
        >
          <div class="fc-s-head__icon">
            <img :src="$util.window.check(configs.third.headIconByPhone,configs.third.headIcon)" />
          </div>
          <div
            class="fc-s-head__close"
            @click.stop="dialogSuccessVisible = false"
          >
            <img src="../../assets/default/Vector@2x.png" />
          </div>
        </div>
        <div
          class="fc-s-title"
          v-html="$util.rex.getHtml($util.window.check(configs.third[`${nowL.code}titleByPhone`] || configs.third.titleByPhone,configs.third[`${nowL.code}title`] || configs.third.title))"
        ></div>
        <div
          class="fc-s-desc"
          v-html="$util.rex.getHtml($util.window.check(configs.third[`${nowL.code}descByPhone`] || configs.third.descByPhone,configs.third[`${nowL.code}desc`] || configs.third.desc))"
        ></div>
        <div class="fc-s-btn">
          <div
            v-for="item,index in configs.third.btn"
            :key="index"
            class="fc-s-btn-item"
          >
            <div
              v-if="item.qrcode || item.qrcodeUrl"
              class="fc-s-btn-qrcode"
            >
              <img :src="item.qrcode || item.qrcodeUrl" />
            </div>
            <div
              class="btn"
              @click="$util.route.go(item.go)"
            >
              <img :src="item.icon" />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sendSmsRicePOS, registerRicePOS, placeOrdersRicePOS, checkUserName, checkUserPhone } from '@/js/api/api'
import langMixin from '../../mixins/lang';
import idb_ from '@/js/idb/indexDb';
const idb = new idb_('official');
import QRCode from 'qrcode';

export default {
  name: "full-screen-ad",
  mixins: [langMixin],
  props: {
    // * 作為組件時, 外部控制的  model
    show: {
      default () {
        return false;
      }
    },
    // * 是否開屏顯示
    firstShow: {
      default () {
        return true;
      }
    },
    // * 配置項
    configs: {
      default () {
        return {}
      }
    },
    // * 是否插入到 body
    appendBody: {
      default () {
        return true;
      }
    },
    // * 是否顯示快速編輯
    showQuickEdit: {
      default () {
        return false
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  model: {
    prop: "show",
    event: "check"
  },
  created () {
    this.dialogVisible = this.firstShow;
  },
  watch: {
    show (n) {
      if (n) this.dialogVisible = true;
    },
    firstShow (n) {
      this.dialogVisible = n;
    },
    dialogVisible (n) {
      if (!n) this.$emit('check', false)
    },
    "configs.third": {
      deep: true,
      handler (n) {
        if (this.dialogSuccessVisible) {
          let third = this.configs.third.btn;
          for (let i = 0; i < third.length; i += 1) {
            this.getQrcode(i, third[i].go)
          }
        }
      }
    },
    dialogSuccessVisible (n) {
      if (n) {
        let third = this.configs.third.btn;
        for (let i = 0; i < third.length; i += 1) {
          this.getQrcode(i, third[i].go)
        }
      }
    }
  },
  data () {
    const checkUserPhoneEvent = (rule, value, callback) => {
      if (value) {
        checkUserPhone({
          mobile: value,
          type: 2
        }).then(res => {
          if (!res.success) {
            this.$message.error(this.t_('fullUserPhoneUse', '聯絡電話已存在!'));
            this.userPhoneUse = true;
            callback(new Error(' '));
          } else {
            this.userPhoneUse = false;
            callback();
          }
        })
      } else {
        callback();
      }
    }

    const checkUserNameEvent = (rule, value, callback) => {
      if (value) {
        checkUserName({
          userName: value,
        }).then(res => {
          if (!res.success) {
            this.$message.error(this.t_('fullUserNameUse', '賬戶名已存在!'));
            this.userNameUse = true;
            callback(new Error(' '));
          } else {
            this.userNameUse = false;
            callback();
          }
        })
      } else {
        callback();
      }
    }

    return {
      // * 全屏廣告
      dialogVisible: true,
      // * 立即登記
      dialogRegisterVisible: false,
      // * 成功彈窗
      dialogSuccessVisible: false,
      // * checkClass
      checkClass: "",
      // * 提交的 form
      subForm: {
        mobile: "",
        countryId: "1",
        code: "",
        passWord: "",
        userName: ""
      },
      subRules: {
        mobile: [
          { validator: checkUserPhoneEvent, trigger: 'blur' }
        ],
        userName: [
          { validator: checkUserNameEvent, trigger: 'blur' }
        ]
      },
      // * 區號 cid 1 hk, 2 mo , 3 cn , 4 sgp
      region: [
        {
          value: '1',
          label: '+852',
        },
        {
          value: '2',
          label: '+853',
        },
        {
          value: '3',
          label: '+86',
        },
      ],
      // * 短信發送成功
      smsSuccess: false,
      // * 表示發送了
      isSmsSend: false,
      smsLoading: false,
      // * 短信倒計時
      timeout: 60,
      subLoading: false,
      nowL: {
        code: 'HK',
        name: '繁體'
      },
      langFile: {},
      userNameUse: true,
      userPhoneUse: true
    }
  },
  computed: {
    isEdit () {
      try {
        let isEdit = this.$store.getters.getIsEdit
        if (isEdit) {
          this.dialogVisible = false;
        }
        return isEdit
      } catch (error) {
        return false
      }
    }
  },
  created () {
    this.initLang();
    this.$changeLang.set('fullScreenAd', this.initLang)
  },
  methods: {
    // * 語言
    t_ (key, def) {
      if (this.langFile[this.nowL.code]) {
        return this.langFile[this.nowL.code][key] || def
      }
      else return def;
    },

    initLang () {

      let language = this.$storage.get('language')
      let now_language = this.$storage.get('now_language')
      if (!now_language) now_language = 'HK';
      for (let i = 0; i < language.length; i += 1) {
        if (language[i].code == now_language) {
          this.nowL = language[i]
          break;
        }
      }

      idb.get('langFile').then((langFile_ = {}) => {
        this.langFile = langFile_;
      })
    },

    // * 編輯
    clickItem (e, x) {
      if (this.isEdit || this.showQuickEdit) {
        this.checkClass = e;
        this.$emit('clickItem', e, x);
      }
    },

    // * 點擊立即搶購
    submitEvent () {
      // * 關閉彈窗
      this.dialogVisible = false;
      this.dialogRegisterVisible = true;
    },

    // * 快速切換
    dialogVisibleEvent (t) {
      if (this.isEdit || this.showQuickEdit) {
        this.dialogVisible = false;
        this.dialogRegisterVisible = false;
        this.dialogSuccessVisible = false;
        if (t == 0) {
          this.dialogVisible = true;
          this.clickItem('fc-dialog')
        } else if (t == 1) {
          this.dialogRegisterVisible = true
          // this.clickItem('fc-dialog');
        }
        else if (t == 2) {
          this.dialogSuccessVisible = true;
          this.clickItem('fc-success-dialog')
        }
      }
    },

    // * 發送驗證碼
    sendMsg () {
      if (this.smsSuccess || this.smsLoading) return;
      let { mobile, countryId } = this.subForm;
      // * 先判斷是否已存在
      checkUserPhone({
        mobile: mobile,
        type: 2
      }).then(res => {
        if (res.success) {
          mobile = mobile + '';
          let start = () => {
            let i = 60;
            let index = setInterval(() => {
              if (i < 0) {
                this.smsSuccess = false;
                clearInterval(index)
              } else {
                this.timeout = i;
                i -= 1;
              }
            }, 1000);
          }
          if (countryId == '3' && mobile.length != 11) {
            this.$message.error(this.t_('fullPhoneErrorText', '請輸入正確的手機號!'));
          } else if (countryId != '3' && mobile.length != 8) {
            this.$message.error(this.t_('fullPhoneErrorText', '請輸入正確的手機號!'));
          } else {
            this.smsLoading = true;
            sendSmsRicePOS({
              type: 22,
              phone: mobile,
              countryId: countryId
            }).then(res => {
              if (res.success) {
                this.smsLoading = false;
                this.$message.success(this.t_('fullSmsSendSuccess', '驗證碼發送成功!'));
                this.smsSuccess = true;
                this.isSmsSend = true;
                start();
              }
            })
          }
        }
      })
    },

    // * 提交信息
    subLogin () {
      let { mobile, countryId, code, userName, passWord } = this.subForm;
      mobile = mobile + '';
      if (countryId == '3' && mobile.length != 11) {
        this.$message.error(this.t_('fullPhoneErrorText', '請輸入正確的手機號!'));
      } else if (countryId != '3' && mobile.length != 8) {
        this.$message.error(this.t_('fullPhoneErrorText', '請輸入正確的手機號!'));
      } else if (this.userPhoneUse) {
        this.$message.error(this.t_('fullUserPhoneUse', '聯絡電話已存在!'));
      } else if (!this.isSmsSend) {
        this.$message.error(this.t_('fullSmsNoSend', '您還未發送驗證碼!'));
      } else if (!code) {
        this.$message.error(this.t_('fullSmsNoInput', '請輸入驗證碼!'));
      } else if (!userName) {
        this.$message.error(this.t_('fullNameNoInput', '請輸入賬戶名!'));
      } else if (this.userNameUse) {
        this.$message.error(this.t_('fullUserNameUse', '賬戶名已存在!'))
      } else if (!passWord) {
        this.$message.error(this.t_('fullPAssNoInput', '請輸入密碼!'));
      } else if (passWord.length < 8 || passWord.length > 16) {
        this.$message.error(this.t_('fullPassNoInputLength', '請設置8~16位密碼，需包含字母和數字!'));
      } else if (!passWord.match(/[a-zA-Z]/g) || !passWord.match(/\d/g)) {
        this.$message.error(this.t_('fullPassNoInputLength', '請設置8~16位密碼，需包含字母和數字!'));
      } else {
        this.subLoading = true;
        registerRicePOS({
          mobile: mobile,
          code: code,
          countryId: countryId,
          passWord: passWord,
          userName: userName
        }).then(res => {
          this.subLoading = false;
          if (res.success) {
            let id = res.data;
            this.toPlace(id)
          } else {
            if (res.errCode == 'SMS.0001') {
              this.$message.error(this.t_('fullSmsError', '驗證碼錯誤!'))
            } else if (res.errCode == 'LOGIN.0012') {
              this.$message.error(this.t_('fullRegisterRepeat', '請勿重複註冊!'))
            } else {
              this.$message.error(res.errMsg)
            }
          }
        })
      }
    },

    // *購買
    toPlace (uid) {
      placeOrdersRicePOS({
        userId: uid,
        setMealId: this.configs.setMealId,
        purchaseSpecId: this.configs.purchaseSpecId,
        purchaseQuantity: 1,
        totalAmount: 0,
      }, uid).then(res => {
        if (res.success) {
          this.dialogRegisterVisible = false;
          this.dialogSuccessVisible = true;
        } else {
          this.$message.error(res.errMsg || this.t_('fullPlaceError', '似乎出現問題,請稍後再試!'))
        }
      }).catch(() => {
        this.$message.error(this.t_('fullPlaceError', '似乎出現問題,請稍後再試!'))
      })
    },

    // * 關閉第一個框
    dialogVisibleClose () {
      this.dialogVisible = false;
      this.dialogRegisterVisible = false;
      this.dialogSuccessVisible = false;
      this.$emit('close')
    },

    getQrcode (index, value) {
      if (!value) return
      QRCode.toDataURL(value, { margin: 1 }, (err, url) => {
        this.$set(this.configs.third.btn[index], 'qrcodeUrl', url);
      })
    },

  }
} 
</script>

<style lang="less" scoped>
// * 前往 fullScreen.less 編輯
</style>