/*
 * @Author: lbh
 * @Date: 2021-04-29 15:10:36
 * @LastEditors: lbh
 * @LastEditTime: 2024-10-26 10:27:58
 * @Description: 全部接口
 */
import axios from '@/js/utils/http';

let host = location.origin;
if (host.includes('gingersoft')) {
  host = 'https://m.ricepon.com';
} else if (host.includes('120.79.50')) host = 'https://hktest.ricepon.com';
else if (host.includes('aws-www')) host = 'https://aws.ricepon.com';
let riceponSystem = host + '/ricepon-system/api';
let riceponActivities = host + '/ricepon-activities/api';
let riceponMember = host + `/ricepon-member/api`;
let riceponSupport = host + '/ricepon-support/api';
// 添加 免費申請 記錄
export const addHomeUser = (params) =>
  axios.body(riceponSystem + '/public/home/addHomeUser', params);

/**
 * 新增頁面
 * @param {
 *  brandId
 *  pageName 頁面名稱
 *  pageNameDraft 草稿內容
 *  pageTitle 頁面標題
 *  pageTitleDraft 標題草稿
 *  pageValue 配置信息
 *  pageValueDraft 配置信息草稿
 *  release 是否發佈：0 不發佈 1 發佈
 *  domainName 域名
 * } params
 * @returns
 */
export const addPage = (params) =>
  axios.body(
    `${riceponActivities}/home/officialWebsiteComponentConfig/insert`,
    params
  );

/**
 *  修改頁面
 * @param {
 *  id  頁面id
 *  brandId
 *  pageName 頁面名稱
 *  pageNameDraft 草稿內容
 *  pageTitle 頁面標題
 *  pageTitleDraft 標題草稿
 *  pageValue 配置信息
 *  pageValueDraft 配置信息草稿
 *  release 是否發佈：0 不發佈 1 發佈
 *  domainName 域名
 * } params
 * @returns
 */
export const updatePage = (params) =>
  axios.body(
    `${riceponActivities}/home/officialWebsiteComponentConfig/update`,
    params
  );

/**
 * 查詢頁面組件列表
 * @param {
 *  brandId
 *  pageTitle 頁面標題
 *  pageIndex 頁碼
 *  pageSize 條數
 * } params
 * @returns
 */
export const getPageConfigList = (params) =>
  axios.get(
    `${riceponActivities}/home/officialWebsiteComponentConfig/getList`,
    params
  );

/**
 * 發佈頁面
 * @param {
 *  brandId
 * } params
 * @returns
 */
export const pushPage = (params) =>
  axios.post(
    `${riceponActivities}/home/officialWebsiteComponentConfig/release`,
    params
  );

/**
 * 發佈頁面 2023-4-1 新
 * @param {
 *  id 頁面id
 * } params
 * @returns
 */
export const updateComponentConfig = (params) =>
  axios.post(
    `${riceponActivities}/home/officialWebsiteComponentConfig/updateComponentConfig`,
    params
  );

/**
 * 添加 頭部  底部
 * @param {
 *  brandId
 *  name 配置名稱
 *  nameDraft 配置名稱 草稿
 *  configValue 配置值
 *  configValueDraft 配置值 草稿
 *  display 是否顯示  0 不顯示 1 顯示
 *  domainName 域名
 *  type 位置：1 頭部  2 底部
 * } params
 * @returns
 */
export const addHeaderOrFooter = (params) =>
  axios.body(
    `${riceponActivities}/home/officialWebsiteHeadConfig/insert`,
    params
  );

/**
 *修改 頭部  底部
 * @param {
 *  id
 *  brandId
 *  name 配置名稱
 *  nameDraft 配置名稱 草稿
 *  configValue 配置值
 *  configValueDraft 配置值 草稿
 *  display 是否顯示  0 不顯示 1 顯示
 *  domainName 域名
 *  type 位置：1 頭部  2 底部
 * } params
 * @returns
 */
export const updateHeaderOrFooter = (params) =>
  axios.body(
    `${riceponActivities}/home/officialWebsiteHeadConfig/update`,
    params
  );

/**
 * 查詢頭部底部列表
 * @param {
 *  brandId
 *  type 位置：1 頭部  2 底部 3 語言列表 , 4 頁面固定文字語言
 *  pageIndex 頁碼
 *  pageSize 條數
 * } params
 * @returns
 */
export const getHeaderOrFooterList = (params) =>
  axios.get(
    `${riceponActivities}/home/officialWebsiteHeadConfig/getList`,
    params
  );

/**
 * 查詢頁面是否重複
 * @param {
 *  brandId
 *  pageName
 * } params
 * @returns
 */
export const pageExists = (params) =>
  axios.post(
    `${riceponActivities}/home/officialWebsiteComponentConfig/exists`,
    params
  );

/**
 * 获取全部页面
 * @param {
 *  brandId
 * } params
 * @returns
 */
export const getByBrandId = (params) =>
  axios.get(
    `${riceponActivities}/home/officialWebsiteComponentConfig/getByBrandId`,
    params
  );
/**
 * 刪除頁面
 * @param {
 * id
 * } params
 * @returns
 */
export const deletePage = (params) =>
  axios.post(
    `${riceponActivities}/home/officialWebsiteComponentConfig/delete`,
    params
  );

/**
 * 獲取 oss 上傳 token
 * @param {*} params
 * @returns
 */
export const getSignature = (params) =>
  axios.get(`${riceponMember}/public/aliOss/getSignature`, params);

/**
 * oss 上傳
 * @param {*} url
 * @param {*} params
 * @returns
 */
export const ossUploadImg = (url, params) => axios.upload(url, params);

/**
 * * 自主註冊, 發送短信
 * @param {*} params
 * @returns
 */
export const sendSmsRicePOS = (params) =>
  axios.post(`${riceponSystem}/public/sms/send`, params);

/**
 * * 自主註冊, 註冊
 * @param {*} params
 * @returns
 */
export const registerRicePOS = (params) =>
  axios.post(`${riceponSystem}/public/user/register`, params);

/**
 * * 自主註冊, 購買服務
 * @param {*} params
 * @returns
 */
export const placeOrdersRicePOS = (params, uid) =>
  axios.body(`${riceponActivities}/serviceOrder/placeOrders`, params, uid);

/**
 * * 自主註冊 檢驗名稱是否已存在
 * @param {
 * * userName
 * } params
 * @returns
 */
export const checkUserName = (params) =>
  axios.get(`${riceponSystem}/public/user/checkUserName`, params);

/**
 * * 自主註冊 檢驗手機號是否已存在
 * @param {1} params
 * @returns
 */
export const checkUserPhone = (params) =>
  axios.post(`${riceponSystem}/public/user/findUser`, params, { apptype: 9 });

/**
 * 埋點數據
 * @param {
 *  appType 來源
 *  webSource  來源session
 *  memberId 會員id
 *  deviceId 设备id
 *  environment 客户端自定义环境信息
 *  brandId 品牌id
 *  restaurantId 餐廳id
 *  logType 埋點類型
 * } params
 * @returns
 */
export const addClickHead = (params) =>
  axios.body(`${riceponSupport}/public/addClickHead`, params);
