/*
 * @Author: lbh
 * @Date: 2023-03-15 18:29:46
 * @LastEditors: lbh
 * @LastEditTime: 2023-05-10 15:10:03
 * @Description: 計算類
 */
const $math = require('mathjs');

export default {
  /**
   * 加
   * @param {*} a
   * @param {*} b
   * @returns
   */
  add(a, b) {
    return parseFloat($math.chain($math.bignumber(a)).add($math.bignumber(b)));
  },
  /**
   * 減
   * @param {*} a
   * @param {*} b
   * @returns
   */
  subtract(a, b) {
    return parseFloat(
      $math.chain($math.bignumber(a)).subtract($math.bignumber(b))
    );
  },
  /**
   * 乘
   * @param {*} a
   * @param {*} b
   * @returns
   */
  multiply(a, b) {
    return parseFloat(
      $math.chain($math.bignumber(a)).multiply($math.bignumber(b))
    );
  },
  /**
   * 除
   * @param {*} a
   * @param {*} b
   * @returns
   */
  divide(a, b) {
    return parseFloat(
      $math.chain($math.bignumber(a)).divide($math.bignumber(b))
    );
  },
  /**
   * 將數字轉為 $100,000.00格式
   * @param {*} a  金額
   * @param {*} b  $
   * @returns
   */
  toPrice(a = 0, b = '') {
    a = a.toString();
    if (a.includes('.')) {
      return `${b}${a.split('.')[0].replace(/(?=\B(\d{3})+$)/g, ',')}.${
        a.split('.')[1]
      }`;
    } else {
      return `${b}${a.replace(/(?=\B(\d{3})+$)/g, ',')}.0`;
    }
  },
};
