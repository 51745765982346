let allFun = {};

class listen {
  constructor(type) {
    this.type = type;
  }
  set(key, fun) {
    if (typeof fun === 'function') {
      let json = {
        fun: fun,
      };
      allFun[`${this.type}_${key}`] = json;
    }
  }
  togger() {
    for (let i in allFun) {
      if (i.includes(`${this.type}_`)) {
        allFun[i].fun.call();
      }
    }
  }
}

export default listen;
