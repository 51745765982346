/*
 * @Author: lbh
 * @Date: 2022-07-16 14:44:21
 * @LastEditors: lbh
 * @LastEditTime: 2023-08-17 11:02:20
 * @Description: 埋點
 */
import { addClickHead } from '@/js/api/api';
let point = {
  log(obj = {}) {
    let params = {
      logType: obj.logType || '',
      appType: obj.appType || '7',
      webSource: 'PC',
      memberId: '-1', // 用戶id
      deviceId: 'laiziguanwang', // 用戶 token
      environment: '',
      brandId: '',
      restaurantId: '',
      contentType: obj.contentType || '',
      contentId: obj.contentId || '',
    };
    addClickHead(params);
  },
};

export default point;
