/*
 * @Author: lbh
 * @Date: 2022-01-10 15:50:11
 * @LastEditors: lbh
 * @LastEditTime: 2024-08-29 14:06:16
 * @Description: file content
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
  Drawer,
  Collapse,
  CollapseItem,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  Tooltip,
  Button,
  Image,
  Tabs,
  TabPane,
  Empty,
  Carousel,
  CarouselItem,
  Switch,
  Table,
  TableColumn,
  Pagination,
  Upload,
  Checkbox,
  ColorPicker,
  ButtonGroup,
  Tag,
  Dialog,
  Message,
  MessageBox,
  Loading,
  Notification,
  Radio,
  InputNumber,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import util from '@/js/utils/util';
import './css/animate.min.css';

import idb_ from './js/idb/indexDb';
const idb = new idb_('official');
import 'amfe-flexible'; //引入rem自适应
import Contextmenu from 'vue-contextmenujs';
import storage from './js/utils/storage';

import selfCell from './components/edit/cell.vue';
import selfSelectPX from './components/edit/selectPX.vue';
import selfCheckPcOrPhone from './components/edit/checkPcOrPhone.vue';
import selfUpload from './components/upload.vue';
import selfButton from './components/com/button.vue';
import selfPagePicker from './components/mod/pagePicker.vue';
import topWhatsApp from './components/mod/topWhatsApp';
import rfullScreenAd from './components/mod/fullScreenAd';
import selfTips from './components/com/tips.vue';
import selfFontSize from './components/com/fontSize.vue';
import progressiveImage from './components/com/progressiveImage.vue';
import listen from './js/utils/listen';
import './assets/font/font.css';
import tipText from './js/utils/tip';
import listenJS_ from '@/js/utils/listenJS.js';
let listenJS = new listenJS_('lang');
let listenJS2 = new listenJS_('langType');
import VueAnimateNumber from 'vue-animate-number';

import i18n from '@/js/i18n/index.js';

import mathJs from '@/js/utils/math';
import point from '@/js/utils/point';

import './css/srceen.css';
import './css/fullScreen.less';

Vue.prototype.$util = util;
Vue.prototype.$math = mathJs;
Vue.prototype.$point = point;
Vue.use(Contextmenu);
Vue.component('selfCell', selfCell);
Vue.component('selfSelectPX', selfSelectPX);
Vue.component('selfCheckPcOrPhone', selfCheckPcOrPhone);
Vue.component('selfUpload', selfUpload);
Vue.component('selfPagePicker', selfPagePicker);
Vue.component('selfButton', selfButton);
Vue.component('selfTips', selfTips);
Vue.component('selfFontSize', selfFontSize);
Vue.component('progressiveImage', progressiveImage);
Vue.config.productionTip = false;
// Vue.use(ElementUI);
Vue.component(Drawer.name, Drawer);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Divider.name, Divider);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(Input.name, Input);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Button.name, Button);
Vue.component(Image.name, Image);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Empty.name, Empty);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Switch.name, Switch);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Pagination.name, Pagination);
Vue.component(Upload.name, Upload);
Vue.component(Checkbox.name, Checkbox);
Vue.component(ColorPicker.name, ColorPicker);
Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(Tag.name, Tag);
Vue.component(Dialog.name, Dialog);
Vue.component(Radio.name, Radio);
Vue.component(InputNumber.name, InputNumber);

Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

Vue.use(topWhatsApp);
Vue.use(rfullScreenAd);
Vue.use(VueAnimateNumber);
Vue.prototype.$listen = listen;
Vue.prototype.$tipText = tipText;
Vue.prototype.$storage = storage;
Vue.prototype.$changeLang = listenJS;
Vue.prototype.$listenJS = listenJS2;
Vue.prototype.$idb = idb;
// Vue.prototype.$i18n = i18n;
Vue.config.devtools = true;

window.$listen = listen;
new Vue({
  router,
  store,
  i18n,
  created() {
    let userInfo = storage.get('user_info');
    if (userInfo) {
      this.$store.commit('setIsEdit', true);
    }
    let isMobile = this.$util.window._isMobile();
    this.$store.commit('setIsMobile', isMobile);
    // util.color.setThemeColor();
    //禁止全局右鍵
    document.oncontextmenu = () => {
      return false;
    };
    window.onresize = () => {
      listen.triggerAll(window.innerWidth);
    };
    // 查詢 語言
    util.system.getLanguageList();

    if (document.querySelector('.fc-dialog'))
      document.querySelector('.fc-dialog').remove();
    if (document.querySelector('.v-modal'))
      document.querySelector('.v-modal').remove();
    if (document.querySelector('.full-screen-ad-box'))
      document.querySelector('.full-screen-ad-box').remove();
  },
  mounted() {
    // 延遲久一點, 避免有頁面沒有生成
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'));
    }, 15000);
  },
  render: (h) => h(App),
}).$mount('#app');
