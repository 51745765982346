<!--
 * @Author: lzh
 * @Date: 2022-06-23 18:15:06
 * @LastEditors: lbh
 * @LastEditTime: 2024-01-10 12:25:10
 * @Description: file content
-->
<template>
  <div class="layer">
    <div class="top-whats-app-box px-whats-app">
      <div class="top-title">
        <!-- <div></div> -->
        <div>{{t_('topBigTitle','請留底你嘅資料，我哋好快會有專人與你聯絡')}}</div>
        <i
          @click="closeLayer"
          class="el-icon-close close"
        ></i>
      </div>
      <div class="content scroll-bar">
        <el-form
          :model="numberValidateForm"
          ref="numberValidateForm"
          :rules="rules"
          class="demo-ruleForm"
        >
          <el-form-item
            :label="t_('topCountryTitle','國家/地區')"
            prop="address"
            hide-required-asterisk="false"
          >
            <el-select
              class="_el-select"
              v-model="numberValidateForm.address"
              :placeholder="t_('','請選擇國家/地區')"
              @change="changeAddress"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="t_('topUserName','姓名')"
            prop="name"
          >
            <el-input
              type="text"
              v-model.number="numberValidateForm.name"
              autocomplete="off"
              :placeholder="t_('topUserNamePlaceholder','請輸入您的姓名')"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="t_('topTelphoneName','聯絡電話')"
            prop="phone"
            class="select-input-box required"
          >
            <div class="phone-box">
              <el-select
                v-model="numberValidateForm.region"
                placeholder=""
              >
                <el-option
                  v-for="item in region"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <el-input
                v-model.number="numberValidateForm.phone"
                autocomplete="off"
                :placeholder="t_('topTelphonePlaceholder','請輸入您的聯絡電話')"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item
            :label="t_('topRemarkName','備註')"
            class="desc-box"
          >
            <div class="cell">
              <div
                v-for="item,index in labels"
                :key="index"
                class="label"
                :class="{active:item.checked}"
                v-text="t_(item.key,item.label)"
                @click="item.checked = !item.checked"
              ></div>
            </div>
            <el-input
              :placeholder="t_('topRemarkPlaceholder','請輸入您需要備註的信息')"
              type="textarea"
              v-model="numberValidateForm.description"
            ></el-input>
          </el-form-item>
          <el-form-item class="btn-box">
            <selfButton
              theme="line"
              @onClick="resetForm()"
            >{{t_('cancel','取消')}}</selfButton>
            <selfButton
              id="selfButton"
              :loading="btnLoading"
              @onClick="submitForm('numberValidateForm')"
            >{{t_('submit','提交')}}</selfButton>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// let langFile = {};
import { addHomeUser } from "@/js/api/api";
import idb_ from '@/js/idb/indexDb';
const idb = new idb_('official');
export default {
  name: 'self-top-whatsApp',
  data () {
    return {
      options: [
        {
          value: '中國香港',
          label: '中國香港',
        },
        {
          value: '中國大陸',
          label: '中國大陸',
        },
        {
          value: '中國澳門',
          label: '中國澳門',
        },
      ],
      region: [
        {
          value: '852',
          label: '+852',
        },
        {
          value: '853',
          label: '+853',
        },
        {
          value: '+86',
          label: '+86',
        },
      ],
      value: '',
      numberValidateForm: {
        name: '',
        address: '中國香港',
        restaurantName: '',
        phone: '',
        region: '+852',
        description: '',
        emailAddress: '',
        restaurantType: '1',
        source: 1
      },
      rules: {
        phone: [
          {
            validator: (rule, value, callback) => {
              let region = this.numberValidateForm.region;
              if (value === '') {
                callback(new Error(this.t_('topTelphonePlaceholder', '請輸入電話號碼')));
              } else {
                let p = /[1][3-8]\d{9}/g;
                let hk = /([2|3|4|5|6|8|9])\d{7}/g;
                let am = /\d{8}/g;
                if (region.includes('852') && !hk.test(value)) {
                  callback(new Error(this.t_('topPleasePhoneConfirm', '請輸入正確的聯絡電話')));
                } else if (region.includes('853') && !am.test(value)) {
                  callback(new Error(this.t_('topPleasePhoneConfirm', '請輸入正確的聯絡電話')));
                } else if (region.includes('86') && !p.test(value)) {
                  callback(new Error(this.t_('topPleasePhoneConfirm', '請輸入正確的聯絡電話')));
                } else {
                  callback();
                }
              }
            },
            trigger: 'blur'
          }
        ]
      },
      btnLoading: false,
      isShowMeg: false,
      nowL: {
        code: 'HK',
        name: '繁體'
      },
      langFile: {

      },
      labels: [
        { label: "連鎖店", key: "remarkLabel1", checked: false },
        { label: "加盟店", key: "remarkLabel2", checked: false },
        { label: "新店開業", key: "remarkLabel3", checked: false },
        { label: "CMR會員系統", key: "remarkLabel4", checked: false },
        { label: "餐檯碼", key: "remarkLabel5", checked: false },
        { label: "外賣系統", key: "remarkLabel6", checked: false },
      ]
    };
  },
  props: {
    isShow: {
      default () {
        return true;
      },
    },
  },
  created () {
    this.initLang();
    this.$changeLang.set('topWhatsSpp', this.initLang)
  },
  methods: {
    t_ (key, def) {
      if (this.langFile[this.nowL.code]) {
        return this.langFile[this.nowL.code][key] || def
      }
      else return def;
    },
    initLang () {
      let language = this.$storage.get('language')
      let now_language = this.$storage.get('now_language')
      if (!now_language) now_language = 'HK';
      for (let i = 0; i < language.length; i += 1) {
        if (language[i].code == now_language) {
          this.nowL = language[i]
          break;
        }
      }

      idb.get('langFile').then((langFile_ = {}) => {
        console.log('langFile', langFile_);
        this.langFile = langFile_;

        // 初始化 options
        // options
        let code = this.nowL.code;
        let lang_ = langFile_[code] || {};
        this.options[0].value = lang_['topHKName'] || '中國香港'
        this.options[0].label = lang_['topHKName'] || '中國香港'
        this.options[1].label = lang_['topChinaName'] || "中國大陸";
        this.options[1].label = lang_['topChinaName'] || "中國大陸";
        this.options[2].label = lang_['topMaName'] || "中國澳門";
        this.options[2].label = lang_['topMaName'] || "中國澳門";

        this.numberValidateForm.address = lang_['topHKName'] || '中國香港';

        this.rules.name[0].message = lang_['topUserNamePlaceholder'] || '請輸入您的聯絡電話';
        this.rules.restaurantName[0].message = lang_['topRestaurantPlaceholder'] || '請輸入您的餐廳名稱';
      })

      this.$idb.get('langFile_').then(result => {
        console.log(result);
        let labels = [];
        for (let i in result) {
          let label = result[i]
          if (label.key && label.key.includes('remarkLabel') && label.use) {
            labels.push({
              label: label.HK,
              key: label.key,
              checked: false
            })
          }
        }

        let labels_ = this.labels;
        for (let i in labels) {
          for (let j = 0; j < labels_.length; j += 1) {
            if (labels_[j].key == labels[i].key) {
              labels_.splice(j, 1)
              j -= 1;
            }
          }
        }
        this.labels = [...labels, ...labels_]
      })

    },
    submitForm (formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          // alert('submit!');
          let form = this.$util.object.clone(this.numberValidateForm);
          form.phone = form.region + form.phone;
          let eleId = document.querySelector('#selfButton')
          let labels = this.labels;
          let sel = [];
          for (let i in labels) {
            if (labels[i].checked) sel.push(labels[i].label)
          }
          form.description = `${sel.join(',')} ${form.description}`

          addHomeUser(form).then(res => {
            this.btnLoading = false;
            if (res.success) {
              this.isSubmit = true;
              if (eleId) {
                this.isShowMeg = true
                eleId.classList.add('clickButtonStyle')
                setTimeout(() => {
                  eleId.classList.remove('clickButtonStyle')
                  this.isShowMeg = false
                  this.closeLayer();
                }, 2000)
              }
              this.$alert(this.t_('topSubmitAlertSuccessText', '您的預約申請已提交成功，我們將會在24小時内與你取得聯係！'), this.t_('topSubmitAlertTitle', '溫馨提示'), {
                confirmButtonText: this.t_('topSubmitAlertBtn', '確定'),
                callback: action => {
                }
              });
            } else {
              this.$message.error(this.t_('topSubmitAlertError', '發生錯誤,請檢查您的資料信息'));
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm () {
      this.closeLayer();
    },
    closeLayer () {
      this.numberValidateForm = {
        name: '',
        address: this.langFile[this.nowL.code] ? this.langFile[this.nowL.code]['topHKName'] : '中國香港',
        restaurantName: '',
        phone: '',
        region: '+852',
        description: '',
      }
      let labels = this.labels;
      for (let i in labels) {
        labels[i].checked = false;
      }
      // this.$emit('click', false);
      this.$el.remove();
      this.$emit("close");
      this.$destroy();
    },

    changeAddress () {
      let { address, phone } = this.numberValidateForm;
      if (!phone) {
        let topHKName = this.$tT('topHKName', '中國香港')
        let topChinaName = this.$tT('topChinaName', '中國大陸')
        let topMaName = this.$tT('topMaName', '中國澳門')
        if (address == topHKName) {
          this.numberValidateForm.region = '+852'
        } else if (address == topChinaName) {
          this.numberValidateForm.region = '+86'
        } else if (address == topMaName) {
          this.numberValidateForm.region = '+853'
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.px-whats-app {
  top: 50%;
  transform: translateY(-50%) translateX(-50%) !important;
}
.top-whats-app-box {
  font-family: PingFang SC-Medium, PingFang SC;
  border-radius: 6px;
  position: fixed;
  left: 50%;
  width: 640px;
  max-height: 80vh;
  transform: translateX(-50%);
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.25),
    -4px -4px 10px 1px rgba(0, 0, 0, 0.25);
  background: #fff;
  display: flex;
  flex-direction: column;
  & /deep/ .el-input {
    margin-top: 10px;
    color: #000 !important;
    &::placeholder {
      color: #999 !important;
    }
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .top-title {
    border-radius: 6px 6px 0 0;
    background-color: var(--themeColor);
    padding: 14px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
    color: #f4f6f9;
    div {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
    }
    .close {
      cursor: pointer;
      position: absolute;
      font-size: 24px;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .content {
    border-radius: 6px 6px 6px 6px;
    padding: 40px 54px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    ._el-select {
      width: 100%;
    }
    .select-input-box {
      /deep/ .el-form-item__label {
        width: 100%;
        font-weight: 700;
        color: #000;
        text-align: left;
      }
      /deep/ .el-form-item__content {
        width: 100%;
      }

      .phone-box {
        display: flex;
        align-items: center;
        .el-select {
          width: 90px !important;
        }
        .el-input {
          flex: 1;
          margin-left: 12px;
        }
      }
      /deep/ .el-form-item__content {
        display: inline-block;
      }
    }
    .desc-box {
      /deep/ textarea {
        height: 100px;
        margin-top: 10px;
        max-height: 250px !important;
      }
      .cell {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 10px;
        .label {
          padding: 10px;
          font-size: 16px;
          border: 0.5px solid #ddd;
          color: #333;
          line-height: 18px;
          word-break: break-all;
          margin: 4px;
          border-radius: 8px;
          cursor: pointer;
          transition: 0.3s;
          &.active {
            border-color: var(--themeColor) !important;
            color: var(--themeColor) !important;
          }
        }
      }
    }
    .btn-box {
      margin-top: 30px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      & /deep/ .el-form-item__content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .self-button-box {
          &:nth-child(2) {
            margin-left: 25px;
          }
        }
        #selfButton.clickButtonStyle {
          position: relative;
          background: #adadad;
          color: #c3c3c3;
          border-color: transparent;
          &::after {
            content: "提交成功";
            text-align: center;
            position: absolute;
            top: 0;
            left: calc(100% + 20px);
            background-color: #02da87;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            font-weight: bold;
            box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.1);
            width: 100%;
            height: 100%;
            z-index: 99;
          }
          &::before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -22px;
            content: "";
            border: 10px solid #02da87;
            height: 0;
            width: 0;
            margin-left: -20px;
            border-left-color: transparent;
            border-top-color: transparent;
            border-bottom-color: transparent;
          }
        }
      }
    }
    /deep/ .el-form-item__label {
      font-weight: 700;
      color: #000;
      line-height: 100% !important;
    }
  }
  .required {
    width: 100%;
    /deep/ label::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .px-whats-app {
    width: calc(100vw / 1.53);
    border-radius: 6px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25),
      -2px -2px 10px 0px rgba(0, 0, 0, 0.25);
    transform: translate(-50%, -50%);
    top: 50%;
    .top-title {
      border-radius: 6px 6px 0 0;
      position: relative;
      display: flex;
      justify-content: center;
      div {
        padding: 11px 0;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #f4f6f9;
      }
      i {
        font-size: 24px !important;
        position: absolute;
        right: 20px !important;
      }
    }
    /deep/.el-input {
      margin-top: 10px;
      font-size: 13px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    /deep/ textarea {
      margin-top: 10px !important;
      height: 58px !important;
      font-size: 13px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    /deep/ .el-form-item__label {
      font-weight: bold;
      color: #000;
    }

    .btn-box {
      margin-bottom: 10px;
      & /deep/ .el-button {
        width: 100px !important;
        height: 35px !important;
        line-height: 35px !important;
        padding: 0;
        font-size: 14px !important;
        font-weight: 400;
        border-radius: 6px !important;
      }
    }
    .cell {
      padding-top: 10px !important;
      .label {
        padding: 4px 6px !important;
        font-size: 14px !important;
        border: 0.5px solid #ddd !important;
        color: #333 !important;
        line-height: 18px !important;
        word-break: break-all !important;
        margin: 4px !important;
        border-radius: 8px !important;
        cursor: pointer !important;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .px-whats-app {
    width: calc(100vw / 1.2);
    border-radius: 6px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25),
      -2px -2px 10px 0px rgba(0, 0, 0, 0.25);
    transform: translate(-50%, -50%);
    top: 50%;
    .top-title {
      border-radius: 6px 6px 0 0;
      position: relative;
      color: #f4f6f9;
      div {
        padding: 11px 48px;
        font-size: 13px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
      }
      i {
        font-size: 20px !important;
        position: absolute;
        right: 10px !important;
      }
    }
    .content {
      padding: 15px 20px;
    }
    /deep/.el-input {
      margin-top: 10px;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      .el-input__inner {
        color: #000;
      }
    }
    /deep/ textarea {
      margin-top: 10px !important;
      height: 58px !important;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      .el-textarea__inner {
        color: #000000;
      }
    }
    /deep/ .el-form-item__label {
      font-weight: bold;
      color: #000;
    }

    .btn-box {
      margin-bottom: 0;
      & /deep/ .self-button-box {
        &:nth-child(2) {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
