/*
 * @Author: lbh
 * @Date: 2022-06-14 09:29:13
 * @LastEditors: lbh
 * @LastEditTime: 2023-08-02 14:51:57
 * @Description: file content
 */

let testRouter = [
  {
    path: '/test/home',
    name: 'testHome',
    component: () => import('../views/test/home.vue'),
  },
  {
    path: '/test/cellularModel',
    name: 'test/cellularModel',
    component: () => import('../views/test/cellularModel.vue'),
  },
  {
    path: '/test/bannarSwiper',
    name: 'test/bannarSwiper',
    component: () => import('../views/demo/bannarSwiper.vue'),
  },
];

export default testRouter;
