import { render, staticRenderFns } from "./topWhatsApp.vue?vue&type=template&id=5fa6d7ca&scoped=true"
import script from "./topWhatsApp.vue?vue&type=script&lang=js"
export * from "./topWhatsApp.vue?vue&type=script&lang=js"
import style0 from "./topWhatsApp.vue?vue&type=style&index=0&id=5fa6d7ca&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa6d7ca",
  null
  
)

export default component.exports