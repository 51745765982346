/*
 * @Author: lbh
 * @Date: 2022-01-10 16:04:08
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-12 15:24:15
 * @Description: 工具類
 */

import storage from './storage.js';
import moment from 'moment';
import router from '../../router';
// 节流-防抖  https://www.npmjs.com/package/throttle-debounce
import { debounce } from 'throttle-debounce';
import {
  addHeaderOrFooter,
  updateHeaderOrFooter,
  getHeaderOrFooterList,
} from '@/js/api/api';

import idb_ from '../../js/idb/indexDb';
const idb = new idb_('official');

import listenJS_ from '@/js/utils/listenJS.js';
let listenJS2 = new listenJS_('langType');
let listenJS = new listenJS_('lang');

let _util = {
  time: {
    types: {
      1: 'YYYY-MM-DD HH:mm:ss', // 年月日 時分秒
      2: 'YYYY-MM-DD', // 年月日
      3: 'MM-DD', // 月日
      4: 'HH:mm:ss', // 時分秒
      5: 'HH:mm', // 時分
      6: 'YYYYMMDD',
    },
  },
};
let util = {
  base64: {
    atob(e) {
      e = e.replace(/Rp8/g, '=');
      let t = window.atob(e);
      return t;
    },
    btoa(e) {
      let t = window.btoa(e);
      t = t.replace(/=/g, 'Rp8');
      return t;
    },
  },
  color: {
    /**
     * 設置主題色
     * @param {*} color 主題色
     * @param {*} color2  副色
     */
    setThemeColor(color = '#BE1C42', color2 = '#E3869C') {
      let rgb = this._16ToRgb(color);
      let rgb2 = this._16ToRgb(color2);
      storage.set('theme_color', rgb);
      this.setRootColor('--themeColor', `rgb(${rgb})`);
      this.setRootColor('--themeColor5', `rgb(${rgb2})`);
    },
    setRootColor(name, color) {
      let root = document.documentElement;
      root.style.setProperty(`${name}`, `${color}`);
    },
    /**
     * 獲取主題色
     * @returns
     */
    getThemeColor() {
      return storage.get('theme_color');
    },
    /**
     * 獲取 rgb 格式
     * @param {string} rgba
     * @returns rgba rgb
     */
    getRgba(rgba) {
      if (rgba)
        rgba = rgba.replace('rgba(', '').replace('rgb(', '').replace(')', '');
      // 判斷 rgb 是否合理 不合理 設置默認值
      let rgb = rgba;
      let f = 1;
      try {
        let rgbArr = rgba.split(',');
        if (rgbArr.length == 4) {
          f = rgbArr.splice(3, 1);
          rgb = rgbArr.join(',');
        } else if (rgbArr.length != 3) {
          rgb = '';
        }
      } catch (error) {
        rgb = '';
      }
      return {
        rgba: `rgba(${rgb},${f})`,
        rgb: `rgb(${rgb})`,
        rgba_t: `${rgb},${f}`,
        rgb_t: rgb,
      };
    },
    /**
     * 16 轉 rgb
     * @param {string} color
     * @returns
     */
    _16ToRgb(color) {
      let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
      let sColor = color.toLowerCase();
      if (sColor && reg.test(sColor)) {
        if (sColor.length === 4) {
          let sColorNew = '#';
          for (let i = 1; i < 4; i += 1) {
            sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
          }
          sColor = sColorNew;
        }
        //处理六位的颜色值
        let sColorChange = [];
        for (let i = 1; i < 7; i += 2) {
          sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
        }
        return sColorChange.join(',');
      } else {
        let rgbs = this.getRgba(sColor);
        return rgbs.rgb_t;
      }
    },
  },
  window: {
    get() {
      let J = this.J();
      console.log(J);
      let type = 3;
      if (J.w < 700) type = 1;
      else if (J.w < 1000) type = 2;
      return { type, J };
    },
    /**
     * 窗口變化
     * @param {*} fun
     */
    resize(fun = () => {}) {
      window.addEventListener(
        'resize',
        debounce(40, false, () => {
          fun(this.get());
        })
      );
      fun(this.get());
    },
    // 獲取系統寬高
    J() {
      let outerWidth = window.outerWidth,
        outerHeight = window.outerHeight,
        innerWidth = window.innerWidth,
        innerHeight = window.innerHeight;
      return {
        w: innerWidth > outerWidth ? outerWidth : innerWidth,
        h: innerHeight > outerHeight ? outerHeight : innerHeight,
      };
    },
    // 是否是移動端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return !(flag == null);
    },
    /**
     * * 通過頁面寬度,返回具體的值
     * @param {*} a  移動
     * @param {*} b  pc
     * @param {*} c  pad
     * @returns
     */
    check(a, b, c) {
      let J = this.J();
      let w = J.w;
      // * 移動
      if (w < 600) {
        return a || c || b;
      }
      // * pad
      else if (w < 768.1) {
        return c || a || b;
      }
      // * pc
      else {
        return b;
      }
    },
  },
  object: {
    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    deepClone(obj) {
      if (obj === null || typeof obj !== 'object') {
        return obj;
      }
      if (Array.isArray(obj)) {
        const newArray = [];
        for (let i = 0; i < obj.length; i++) {
          newArray[i] = this.deepClone(obj[i]);
        }
        return newArray;
      }
      const newObj = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          newObj[key] = this.deepClone(obj[key]);
        }
      }
      return newObj;
    },
  },
  // 時間方法
  time: {
    /**
     * 格式化
     * @param {number} type
     * @param {string||number||Date} d
     * @returns
     */
    format(type = 1, d) {
      if (!d) d = new Date(); // 判空
      let date = d; // 取值
      if (typeof d === 'string') date = +d;
      return moment(date).utcOffset(8).format(_util.time.types[type]);
    },
    /**
     * 時間加
     * @param {Date} time
     * @param {num} x
     * @returns
     */
    add(time, x = 0) {
      if (!time) time = new Date();
      time.setDate(time.getDate() + x);
      return time;
    },
  },
  rex: {
    /**
     * 自主換行
     * @param {內容} t
     * @param {是否啟用移動端} x  \N 表示 移動端換行, \n 表示默認換行
     * @returns
     */
    getHtml(t = '', x) {
      // x == 1 &&
      let width = window.innerWidth;
      if (width < 500) {
        return t
          .replace(/\\n/g, '')
          .replace(/\\b/g, '')
          .replace(/\\N/g, '<br />');
      }
      // else if (width < 1000) {
      //   return t
      //     .replace(/\\n/g, '')
      //     .replace(/\\N/g, '')
      //     .replace(/\\b/g, '<br />');
      // }
      return t
        .replace(/\\N/g, '')
        .replace(/\\b/g, '')
        .replace(/\\n/g, '<br />');
    },
  },
  route: {
    go(url = '', configs) {
      let pathname = decodeURI(location.pathname) || '';
      if (!pathname.includes(url)) {
        if (url == 'helpMe') {
          window.$topWhatsApp();
          // this.showOffer = true;
        } else if (url == 'fullScreenAd') {
          window.$fullScreenAd(configs);
        } else if (url == 'contactGo') {
          let contactDom = document.querySelector(
            '.contact-module-box.px-contact-module-box'
          );
          if (contactDom && contactDom.nodeType == 1) {
            // window.scrollTo(0, contactDom.offsetTop);
            window.scrollTo({
              top: contactDom.offsetTop,
              left: 0,
              behavior: 'smooth',
            });
          }
        } else if (url.includes('http')) {
          window.open(url);
        } else {
          if (url.includes('products')) {
            url = url.replace('products/', '');
            router.push({
              name: 'products',
              params: {
                arg: url,
              },
            });
          } else if (url.includes('agreement')) {
            url = url.replace('agreement/', '');
            router.push({
              name: 'agreement',
              params: {
                arg: url,
              },
            });
          } else {
            if (url == '首頁') url = '';
            router.push({
              name: 'Home',
              params: {
                arg: url,
              },
            });
          }
        }
      }
    },
  },
  system: {
    /**
     * * 區分 不同域名
     * @returns brandId
     */
    getBrandId() {
      let origin = location.origin;
      if (origin.includes('www.gingersoft.com')) {
        return 1;
      } else if (origin.includes('www.ricepon.com')) {
        return 2;
      } else {
        origin = location.href;
        if (origin.includes('/official/')) {
          return 1;
        } else if (origin.includes('/ricepon-main/')) {
          return 2;
        }
      }
    },
    // 查詢 語言類型列表
    getLanguageList() {
      let params = {};
      params.brandId = util.system.getBrandId();
      params.type = 3;
      params.pageIndex = 0;
      params.pageSize = 10;
      getHeaderOrFooterList(params).then((res) => {
        if (res.success) {
          let data = res.data;
          let list = data.list;
          let list_ = [];
          for (let i = 0; i < list.length; i += 1) {
            let config = list[i].configValue;
            let configValue = JSON.parse(config);
            if (configValue.activeDefault) {
              if (!storage.get('now_language')) {
                storage.set('now_language', configValue.code);
                // 通知更改語言
                listenJS.togger();
              }
            }
            list_.push({
              id: list[i].id,
              code: configValue.code,
              desc: configValue.desc,
              name: list[i].name,
              activeDefault: configValue.activeDefault,
              isShow: configValue.isShow,
            });
          }
          list_.sort((a, b) => {
            return a.id - b.id;
          });

          this.languageData = list_;
          storage.set('language', list_);
          idb.set('language', list_).then(() => {
            this.getSystemLanguageList();
          });
        }
      });
    },
    // 獲取系統級別 語言表
    getSystemLanguageList() {
      let params = {};
      params.brandId = util.system.getBrandId();
      params.type = 4;
      params.pageIndex = 0;
      params.pageSize = 1000;
      getHeaderOrFooterList(params).then((res) => {
        if (res.success) {
          let data = res.data;
          let list = data.list;
          this.initSystemLanguage(list);
        }
      });
    },
    // 將 語言分拆為 具體格式
    initSystemLanguage(list) {
      // 獲取 語言類型
      idb.get('language').then((languageList) => {
        console.log(languageList);
        let langFile = {};
        let langFile_ = [];
        for (let i = 0; i < languageList.length; i += 1) {
          langFile[languageList[i].code] = {};
        }

        // 處理 list 格式
        for (let i = 0; i < list.length; i += 1) {
          let configValue = list[i].configValue;
          configValue = JSON.parse(configValue);
          configValue.id = list[i].id;
          configValue.use = !(configValue.use === false);
          for (let key in langFile) {
            configValue[key] = configValue[`${key}${configValue.key}`] || '';
            langFile[key][configValue.key] =
              configValue[`${key}${configValue.key}`] || '';
          }
          langFile_.push(configValue);
        }
        // 應用格式
        console.log('langFilelangFilelangFilelangFilelangFile', langFile);
        idb.set('langFile', langFile);
        langFile_.sort((a, b) => {
          return a.id - b.id;
        });
        // 展示格式'
        idb.set('langFile_', langFile_).then(() => {
          listenJS2.togger();
        });
      });
    },
  },
  ele: {
    removeModal() {
      if (document.querySelector('.v-modal')) {
        document.querySelector('.v-modal').remove();
      }
    },
  },
  input: {
    passWord(e) {
      console.log(e);
    },
  },
  image: {
    setOss(src, h = 100) {
      if (src && src.includes('aliyuncs'))
        return `${src}?x-oss-process=image/resize,h_${h},limit_0`;
      return src;
    },
  },
};
export default util;
