/*
 * @Author: lbh
 * @Date: 2022-02-25 14:38:39
 * @LastEditors: lbh
 * @LastEditTime: 2024-01-09 11:51:03
 * @Description: file content
 */
import Vue from 'vue';
import Vuex from 'vuex';
import storage from '@/js/utils/storage';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isEdit: null, // 是否是編輯
    isLogin: null,
    pages: null,
    changeStatus: 0,
    isMobile: false,
    isShowFull: false,
  },
  mutations: {
    setIsLogin(state, isLogin) {
      storage.set_('is_login', isLogin);
      state.isLogin = isLogin;
    },
    setIsEdit(state, isEdit) {
      storage.set_('is_edit', isEdit);
      state.isEdit = isEdit;
    },
    setPages(state, pages) {
      storage.set_('pages', pages);
      state.pages = pages;
    },
    setChangeStatus(state, changeStatus) {
      // storage.set_('changeStatus', changeStatus);
      state.changeStatus = changeStatus;
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setIsShowFull(state, isShowFull) {
      state.isShowFull = isShowFull;
    },
  },
  getters: {
    getIsLogin: (state) => {
      if (state.isLogin) {
        return state.isLogin;
      } else {
        if (storage.get_('is_login')) state.isLogin = storage.get_('is_login');
        return storage.get_('is_login');
      }
    },
    getIsEdit: (state) => {
      if (state.isEdit) {
        return state.isEdit;
      } else {
        if (storage.get_('is_edit')) state.isEdit = storage.get_('is_edit');
        return storage.get_('is_edit');
      }
    },
    getPages(state) {
      if (state.pages) {
        return state.pages;
      } else {
        if (storage.get_('pages')) state.pages = storage.get_('pages');
        return storage.get_('pages');
      }
    },
    getChangeStatus(state) {
      console.log('444', state);
      // if (state.changeStatus) {
      //   return state.changeStatus;
      // } else {
      //   if (storage.get_('changeStatus')) state.changeStatus = storage.get_('changeStatus');
      //   return storage.get_('changeStatus');
      // }
    },
    getIsMobile(state) {
      if (state.isMobile) {
        return state.isMobile;
      } else {
        return false;
      }
    },
    getIsShowFull: (state) => state.isShowFull,
  },
  actions: {},
  modules: {},
});
