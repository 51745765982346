/*
 * @Author: lbh
 * @Date: 2022-01-10 15:50:11
 * @LastEditors: lbh
 * @LastEditTime: 2023-11-21 15:22:31
 * @Description: file content
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import testRouter from './test_router';
import store from '@/store/index';
Vue.use(VueRouter);

const routes = [
  ...testRouter,
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/menu.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/edit/:arg?',
    name: 'edit',
    component: () => import('../views/edit.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/preview/:arg?',
    name: 'preview',
    component: () => import('../views/home.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/404/:arg?',
    name: '404',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/products/:arg?',
    name: 'products',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/:arg?',
    name: 'Home',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/agreement/:arg?',
    name: 'agreement',
    component: () => import('../views/home.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  if (to.meta.isLogin) {
    if (!store.getters.getIsLogin) {
      next('404');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
