/**
 導入
 import langMixin from '../../mixins/lang';
 引入
 mixins: [langMixin],

 取值
  [`${nowL}attention`] || configs.attention

 */
let mixin = {
  data() {
    return {
      nowL: '',
    };
  },
  props: {
    lang: {
      default() {
        return '';
      },
    },
  },
  watch: {
    lang() {
      this.initLang();
    },
  },
  created() {
    this.initLang();
  },
  methods: {
    initLang() {
      let now_language = this.$storage.get('now_language');
      if (now_language === 'HK') now_language = '';
      this.nowL = now_language;
    },
  },
  computed: {
    isEdit() {
      return this.$store.getters.getIsEdit;
    },
  },
};

export default mixin;
