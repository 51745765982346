/*
 * @Author: lbh
 * @Date: 2022-09-23 10:31:25
 * @LastEditors: lbh
 * @LastEditTime: 2024-01-09 14:07:58
 * @Description: 插件
 */
import domVue from '../../do/fullScreenAd.vue';

let currentTWA;
export default {
  install(Vue, options) {
    let f = (configs) => {
      // 避免出现多个 toast 重叠
      if (currentTWA) {
        try {
          currentTWA.close();
        } catch (error) {}
      }
      // * 先判斷有沒有，有就把他刪掉
      if (document.querySelector('.fc-dialog'))
        document.querySelector('.fc-dialog').remove();
      if (document.querySelector('.v-modal'))
        document.querySelector('.v-modal').remove();
      if (document.querySelector('.full-screen-ad-box'))
        document.querySelector('.full-screen-ad-box').remove();
      currentTWA = _create({
        Vue,
        onClose: () => {
          currentTWA = null;
        },
        configs,
      });
    };
    // 託管到 vue
    Vue.prototype.$fullScreenAd = f;
    window.$fullScreenAd = f;
  },
};

function _create({ Vue, onClose, configs }) {
  const Constructor = Vue.extend(domVue);
  let twa = new Constructor({
    propsData: { configs },
    el: document.createElement('div'), // 設置掛載點, 不寫這個會報錯的哦
  });
  twa.$on('close', onClose);
  // 加載到 body
  document.body.appendChild(twa.$el);
  return twa;
}
