<!--
 * @Author: lbh
 * @Date: 2022-12-14 16:15:23
 * @LastEditors: lbh
 * @LastEditTime: 2022-12-17 16:35:53
 * @Description: file content
-->
<template>
  <el-tooltip
    class="com__tips"
    effect="dark"
    :content="content"
    placement="top"
  >
    <i class="self-tips el-icon-question"></i>
  </el-tooltip>
</template>

<script>
export default {
  name: "self-tips",
  props: {
    content: {
      default () {
        return "";
      }
    }
  }
}
</script>

<style lang="less" scoped>
.com__tips {
  font-size: 16px;
}
</style>