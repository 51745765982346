<!--
 * @Author: lbh
 * @Date: 2024-01-05 11:39:26
 * @LastEditors: lbh
 * @LastEditTime: 2024-01-05 14:37:26
 * @Description: file content
-->
<template>
  <div class="check-pc-or-phone-box">
    <div class="title-box">
      <h3>{{title}}</h3>
      <div
        v-if="$slots.pc"
        :class="{active:active=='pc'}"
        @click="active = 'pc'"
      >PC</div>
      <div
        v-if="$slots.phone"
        :class="{active:active=='phone'}"
        @click="active = 'phone'"
      >Phone</div>
      <div
        v-if="$slots.pad"
        :class="{active:active=='pad'}"
        @click="active = 'pad'"
      >Pad</div>
    </div>
    <div v-if="active == 'pc'">
      <slot name="pc"></slot>
    </div>
    <div v-if="active == 'phone'">
      <slot name="phone"></slot>
    </div>
    <div v-if="active == 'pad'">
      <slot name="pad"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "check-pc-or-phone",
  props: {
    title: {
      default () {
        return ''
      }
    },
    defalutActive: {
      default () {
        return 'pc'
      }
    }
  },
  watch: {
    defalutActive (n) {
      this.active = n;
    }
  },
  data () {
    return {
      active: "pc"
    }
  },
}
</script>

<style lang="less" scoped>
.check-pc-or-phone-box {
  .title-box {
    display: flex;
    align-items: center;
    & > div {
      margin-left: 20px;
      padding: 6px 10px;
      background: #ccc;
      color: #000;
      cursor: pointer;
      border-radius: 6px;
      transition: 0.3s;
      &.active {
        background-color: var(--themeColor);
        color: #fff;
      }
    }
  }
}
</style>