<!--
 * @Author: lbh
 * @Date: 2023-12-14 15:20:51
 * @LastEditors: lbh
 * @LastEditTime: 2023-12-14 15:32:20
 * @Description: file content
-->
<template>
  <div>
    <selfCell :title="title">
      <el-select
        :value="value"
        placeholder="請選擇字體大小"
        @change="changeFont"
      >
        <el-option
          v-for="item in 100"
          :key="item"
          :label="`${item}px`"
          :value="`var(--font${item})`"
        >
        </el-option>
      </el-select>
    </selfCell>
  </div>
</template>

<script>
export default {
  name: "font-size-box",
  props: {
    title: {
      default () {
        return '文字大小'
      }
    },
    value: {
      default () {
        return ''
      }
    }
  },
  model: {
    prop: "value",
    event: "select"
  },
  methods: {
    changeFont (e) {
      this.$emit('select', e)
      this.$emit('change')
    }
  }
}
</script>

<style lang="less" scoped>
</style>