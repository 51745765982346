<!--
 * @Author: lbh
 * @Date: 2022-06-21 18:36:47
 * @LastEditors: lbh
 * @LastEditTime: 2024-03-06 14:28:45
 * @Description: file content
-->
<template>
  <div class="px-h4">
    <div class="px-t">
      <div
        class="t"
        v-html="$util.rex.getHtml(title)"
      ></div>
      <selfTips
        v-if="tipContent"
        :content="tipContent"
      />
    </div>
    <div
      class="px-box"
      :style="{'--RPBW':title ? '' : '100%'}"
      :class="{column:column}"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "self-cell",
  props: {
    title: {
      default () {
        return "";
      }
    },
    tipContent: {
      default () {
        return "";
      }
    },
    column: {
      defalt () {
        return false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.px-h4 {
  margin-left: 5px;
  margin-bottom: 10px;
  display: flex;

  .px-t {
    display: flex;
    align-items: center;
    .t {
      font-weight: bold;
      font-size: 14px;
      margin-right: 5px;
    }
  }
  .px-box {
    display: flex;
    margin-left: auto;
    width: var(--RPBW, 70%);
    // flex:1;
    justify-content: end;
    &.column {
      flex-direction: column;
    }
  }
}
</style>