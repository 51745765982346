/*
 * @Author: lbh
 * @Date: 2022-12-03 17:35:36
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-08 16:46:29
 * @Description: file content
 */
// in db_student_config.js

class Table {
  constructor(tableName) {
    this.tableName = tableName;
  }

  get() {
    return {
      dbName: `riceponH5Db_${this.tableName}`, // *数据库名称
      version: 1,
      tables: [
        // 活動表
        {
          tableName: this.tableName, // *表名
          option: { keyPath: 'id' }, // 表配置，即ObjectStore配置，此处指明主键为id
          indexs: [
            // 数据库索引（建议加上索引）
            {
              key: 'id', // *索引名
              option: {
                // 索引配置，此处表示该字段不允许重复
                unique: true,
              },
            },
            {
              key: 'key',
            },
            {
              key: 'value',
            },
          ],
        },
      ],
    };
  }
}

export default Table;
