/*
 * @Author: lbh
 * @Date: 2023-01-12 18:02:26
 * @LastEditors: lbh
 * @LastEditTime: 2023-01-16 12:21:38
 * @Description: 提示語
 */
const tips = {
  textWrap: '輸入[\\n]表示web端換行,輸入[\\b]表示Pad換行,輸入[N]表示移動端換行',
};

export default tips;
