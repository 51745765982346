<!--
 * @Author: lbh
 * @Date: 2024-01-11 15:07:02
 * @LastEditors: lbh
 * @LastEditTime: 2024-01-17 09:30:09
 * @Description: file content
-->
<template>
  <div class="select-px">
    <el-select
      :value="value"
      :filterable="true"
      @change="onChange"
      popper-class="pageSelect"
    >
      <el-option
        v-for="item in 300"
        :key="item"
        :label="`${item}px`"
        :value="`var(--font${item})`"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: "select-px",
  props: {
    value: {
      default () {
        return ''
      }
    }
  },
  model: {
    prop: "value",
    event: "select"
  },
  methods: {
    onChange (e) {
      this.$emit('select', e)
      this.$emit('change')
    }
  }
}
</script>

<style lang="less" scoped>
</style>