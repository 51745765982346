/*
 * @Author: lbh
 * @Date: 2023-06-09 15:07:32
 * @LastEditors: lbh
 * @LastEditTime: 2023-12-07 17:05:47
 * @Description: file content
 *
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import storage from '../utils/storage';
import listenJS_ from '@/js/utils/listenJS.js';
let listenJS2 = new listenJS_('langType');
let listenJS = new listenJS_('lang');
import idb_ from '@/js/idb/indexDb';
const idb = new idb_('official');
let messages = {};

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: storage.get('now_language') || 'HK', // 设置语种
  messages,
});
listenJS2.set('i18n', () => {
  idb.get('langFile').then((langFile) => {
    // i18n.messages = langFile;
    for (let i in langFile) {
      i18n.mergeLocaleMessage(i, langFile[i]);
    }
  });
});
// * 處理默認值
Vue.prototype.$tT = (k, d) => {
  if (typeof d === 'object') {
    let def = d.default;
    return i18n.te(k) ? i18n.t(k, d) : def;
  } else return i18n.te(k) ? i18n.t(k) : d;
};

listenJS.set('i18n', () => {
  // 替換語言
  i18n.locale = storage.get('now_language');
});

export default i18n;
