/*
 * @Author: lbh
 * @Date: 2021-10-14 11:34:22
 * @LastEditors: lbh
 * @LastEditTime: 2022-09-09 09:42:39
 * @Description: 本地存儲
 */
let key_start = 'r_official_';
let storage = {
  /**
   * 保存
   * @param {string} k
   * @param {object} value
   */
  set(k, value) {
    return new Promise((a, b) => {
      let v = value;
      if (typeof value == 'object') v = JSON.stringify(value);
      localStorage.setItem(`${key_start}${this.key[k] || k}`, v);
      a();
    });
  },
  set_(k, value) {
    return new Promise((a, b) => {
      let v = value;
      if (typeof value == 'object') v = JSON.stringify(value);
      sessionStorage.setItem(`${key_start}${this.key[k] || k}`, v);
      a();
    });
  },
  /**
   * 獲取
   * @param {string} k
   * @returns
   */
  get(k) {
    let r = localStorage.getItem(`${key_start}${this.key[k] || k}`);
    try {
      if (r) {
        if (r == 'true') r = true;
        else if (r == 'false') r = false;
        else r = JSON.parse(r);
      }
    } catch (error) {
      return r;
    }
    return r;
  },
  get_(k) {
    let r = sessionStorage.getItem(`${key_start}${this.key[k] || k}`);
    try {
      if (r) {
        if (r == 'true') r = true;
        else if (r == 'false') r = false;
        else r = JSON.parse(r);
      }
    } catch (error) {
      return r;
    }
    return r;
  },
  /**
   * 刪除某一個
   * @param {string} k
   * @param {function} back
   */
  remove(k) {
    return new Promise((a, b) => {
      localStorage.removeItem(`${key_start}${this.key[k] || k}`);
      a();
    });
  },
  remove_(k) {
    return new Promise((a, b) => {
      sessionStorage.removeItem(`${key_start}${this.key[k] || k}`);
      a();
    });
  },
  /**
   * 清除
   * @param {function} back
   */
  clear(back) {
    localStorage.clear();
    sessionStorage.clear();
    if (typeof back == 'function') back();
  },
  key: {
    theme_color: 'theme_color',
    user_info: 'user_info',
  },
};

export default storage;
